<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="4" class="mt-6">
                {{ title }}
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="mt-6">
                <v-btn small depressed color="primary" to="/questions/create">
                  {{ btnTitle }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table :headers="headers" :items="questions" :search.sync="search" :loading="loadingPage">
            <template #[`item.created`]="{ item }">
              <div class="text-xs-center">
                {{ item.created | formatDate("MMM DD, YYYY@HH:mm") }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon x-small :to="`/questions/edit/${item.id}`">
                <v-icon small>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
              <!-- <v-icon small class="mr-2" @click="deleteItem(item.id)">
                {{ icons.mdiDelete }}
              </v-icon> -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">
          Delete Question
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          Are you sure you want to delete this Question?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="info" text @click="dialogDelete = false">
            Cancel
          </v-btn>
          <v-btn color="error" @click="deleteQuestion">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable operator-linebreak */
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiDelete, mdiClose, mdiAccountOutline, mdiLink, mdiFormatText } from "@mdi/js";
import { mapState } from "vuex";
import question, { questionCollection } from "@/services/questionService";
import moodule from "@/services/moduleService";

export default {
  name: "Module",
  data: () => ({
    title: "Questions",
    search: null,
    selected: null,
    moduleId: null,
    btnTitle: "Add Question",
    questions: [],
    loadingPage: false,
    dialogDelete: false,
    icons: {
      mdiPencil,
      mdiDelete,
      mdiClose,
      mdiAccountOutline,
      mdiLink,
      mdiFormatText,
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
    headers() {
      const header = [
        { text: "Title", value: "title" },
        { text: "Module", value: "moduleName" },
        { text: "Created", value: "created" },
        { text: "Actions", value: "actions", sortable: false },
      ];

      return header;
    },
  },

  created() {
    this.getQuestions();
  },

  methods: {
    async getQuestions() {
      try {
        questionCollection.onSnapshot(querySnapshot => {
          const items = [];
          querySnapshot.forEach(async doc => {
            const data = doc.data();
            data.id = doc.id;
            await moodule.getModule(data.moduleId).then(item => {
              if (item.exists) {
                const document = item.data();
                data.moduleName = document.name;
              }
            });
            items.push(data);
          });
          this.questions = items;
        });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: "Data not available" });
      }
    },

    async deleteItem(id) {
      try {
        await question.getQuestion(id).then(doc => {
          if (doc.exists) {
            this.selected = doc.id;
            this.moduleId = doc.data().moduleId;
          }
          this.dialogDelete = true;
        });
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: `An error occurred : ${error.message}` });
      }
    },
    async deleteQuestion() {
      if (this.selected) {
        try {
          question.deleteQuestion(this.selected);

          // await moodule.getModule(this.moduleId).then(doc => {
          //   const data = doc.data();
          //   const qid = doc.id;
          //   const questions = { questions: data.questions - 1 };
          //   moodule.updateModule(qid, questions);
          // });
          this.dialogDelete = false;
          this.selected = null;
          this.moduleId = null;
          this.$store.dispatch("notification/add", { type: "success", message: "Question successfully deleted" });
        } catch (error) {
          this.$store.dispatch("notification/add", { type: "error", message: `An error occurred : ${error.message}` });
        }
      }
    },

    // checkDifference() {
    //   this.form.parent.forEach(child => {
    //     let hasChild = this.temp.some(item => item.id === child.id && item.answer === child.answer);
    //   })
    // },
  },
};
</script>
